import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Raschin
There, Already
We explored the idea of liminal spaces, the spaces in between. Spaces that look abandoned and purposeless without people, but are back to life as soon as someone waiting near by fills it their presence. We looked at empty space and how people fill the space and add stories to those empty spaces.
We chose the bus shelter as the point of contact between the one waiting and their journey. The bus shelter is an existing structure that frames the space that is in between. This space is shaped by waiting. Most of the bus shelters in the mission have this extra space. It was originally built for a payphone to be built in, but has now become a kind of anonymous space. We decided to fill this space.
`}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=rH9cM9k_LS8&feature=emb_logo"
      }}>{`Watch video`}</a></p>
    <p>{`The making process became a period of measuring, modeling, trialing and refining. We began by taping off the bus shelter in order to envision what we were making. Then we made cardboard prototypes which were later refined into the booth. This phase took approximately three weeks and we went through many models, most major shifts in design were due to decisions about how the user will interact with our project or large changes in the type of technology we would be using.`}</p>
    <p><img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2010/12/installation-in-the-mission.jpg",
        "alt": "mission_installation"
      }}></img></p>
    <p>{`We tested our installation and spoke to some locals in the mission about their thoughts. There were a large range of reactions to the bus booth, but a vast majority understood the piece instantly and took a liking to it. One found the simplicity of the piece to be quite beautiful and sat quietly as she took in the experience. Others were so excited that they “tweeted” about it right away.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=B7cHyld5O4Q&feature=emb_logo"
      }}>{`Watch video`}</a></p>
    <p>{`Projectors inside the booth connected to live streaming video feed from the 48-Line bus. We followed the bus to capture footage.
`}<img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2010/12/mibniGoogle.jpg",
        "alt": "mission_installation"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2010/12/inmission01.jpg",
        "alt": "inmission_1"
      }}></img>{`
`}{`![inmission_2]`}<a parentName="p" {...{
        "href": "http://raschin.com/blog/wp-content/uploads/2010/12/inmission02.jpg"
      }}>{`http://raschin.com/blog/wp-content/uploads/2010/12/inmission02.jpg`}</a>{`)
`}<img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2010/12/inmission03.jpg",
        "alt": "inmission_3"
      }}></img></p>
    <p>{`People are waiting to explore There, Already installation`}</p>
    <p>{`Portable installation.`}</p>
    <p><img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2010/12/thereAlready.jpg",
        "alt": "there_already"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2010/12/exhibition.jpg",
        "alt": "exhibit_1"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2010/12/exhibition02.jpg",
        "alt": "exhibit_2"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2010/12/exhibition03.jpg",
        "alt": "exhibit_3"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2010/12/exhibition04.jpg",
        "alt": "exhibit_4"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2010/12/waitingmode.jpg",
        "alt": "waiting_mode"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2010/12/UNfold2.jpg",
        "alt": "unfolde"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2010/12/brainstorming.jpg",
        "alt": "brainstorming"
      }}></img></p>
    <p>{`Sensing the City Exhibition`}</p>
    <p>{`2020 by Raschin.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      